@font-face {
  font-family: "Terminus";
  src: url("../public/fonts/Terminus\ \(TTF\)\ 500.ttf");
}

.a{
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.a:hover{
  text-decoration: none;
  color: #77de69 !important;
}

.a:visited{
  text-decoration: none;
  color: #fff;
}

.App {
  text-align: center;
  font-family: "Roboto";
  color: #122e36;
  background-color: #fff;
  flex-wrap: wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #3a3a39;
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 5px 10px;

}

.App-link {
  color: #61dafb;
}

.main{
  /* background: url("../public/fon.png") center/50%; */
  min-height: calc(100vh - 267px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px 0px;
  font-family: "Roboto";
  color: #122e36;
  background-color: #122e36;
  padding: 0px 0px 20px 0px ;
}

.main-body{
  width: 1100px;
  background-color: white;
  border: 1px solid #3a3a39;
  border-top-style: none;
}

.logo{
  height: 70px;
}

.logo-name{
  height: 30px;
}

.logo-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.logo-menu{
  display: flex;
  gap: 20px;
}

.block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1100px;
  background-color: #e8e8e8f8;
  padding: 30px;
  /* margin-bottom: 20px; */
  gap: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px #e8e8e8ab;
}

.block-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 10px;
  justify-content: space-between;
  border-radius: 10px;

}

.block-item-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  gap: 10px;
  margin: 10px;
  justify-content: flex-start;
  border-radius: 10px;

}

.block-item-pic{
  width: 40%;
  min-width: 40%;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #3a3a39;
}

.block-feedback{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 10px;
  width: 60%;
}

.block-feedback-item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.MuiFormLabel-root.Mui{
  background-color: #fff;
}

.MuiFormLabel-root.Mui-focused {
  color: #77de69 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #77de69 !important;
}

.block-feedback-textfield{
  background-color: #fff;
  border-color: #3a3a39;
  border-radius: 0px !important; 
}

.MuiOutlinedInput-root.Mui-focused{
  border: #77de69;
}

.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-width: 1100px;
}

.font{
  font-family: "Terminus" !important;
  font-size: 1.2rem !important;
  color: white;
}

.button{
  /* background-color: #3a3a39 !important; */
  background-color: #122e36 !important;
  color: #77de69 !important
}

.button:hover{
  background-color: #77de69 !important;
  color: #122e36 !important

}

.icon:hover{
  color: #77de69 !important;

}

.logo-contact{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.6rem;
  color: white;
  width: 150px;
}

.icon{
  color: white;
  margin-right: 5px;
  font-family: "Terminus" !important;
  font-size: 1.2rem !important;
}

.no-padding{
  padding: 2px !important; 
}

.pic{
  max-height: 400px;
}

.banner{
  background-color: black;
  min-width: 100vw;
  max-height: 400px;
  display: flex;
  justify-content: center;
}

.banner-items{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1100px;
}

.banner-font{
  color: #77de69;
  font-size: 2rem !important;
  text-align: left;
  font-family: "Terminus" !important;
  text-shadow: #939393 0.7px 1px 1px;
}

.footer-background{
  background-color: #3a3a39;
  min-height: 150px;
  display: flex;
  justify-content: space-between;
}

.footer{
  color: #fff;
  font-family: "Terminus" !important;
  font-size: 1.2rem !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1100px;
  align-items: center;
  margin: 30px;
}

.footer-block{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
}

.footer-block-item-a:hover{
  cursor: pointer;
  color: #77de69;
}

.footer-logo{
  height: 50px;
}

.footer-logo-name{
  height: 20px;
}

.ugol2{
  display: flex;
  align-items: flex-end;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* @media screen and (max-width: 900px) {
  .header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;
    max-width: 900px;
  }

  .logo{
    height: 30px;
  }
  
  .logo-name{
    height: 10px;
  }
  
  .logo-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .logo-menu{
    display: flex;
    gap: 20px;
  }
} */
