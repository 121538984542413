@font-face {
    font-family: "Terminus";
    src: url("../../../public/fonts/Terminus\ \(TTF\)\ 500.ttf");
}

.App {
    text-align: center;
    font-family: "Roboto";
    color: #122e36;
    background-color: #fff;
    width: 100vw;
}

.App-header {
    background-color: #3a3a39;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 5px 10px;
}

.header-mobile{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    gap: 5px;
    width: 90%;
}

.logo{
height: 50px;
}

.logo-name{
height: 10px;
}

.logo-div{
display: flex;
flex-direction: column;
align-items: center;
gap: 5px;
}

.logo-contact-mobile{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.3rem !important;
    color: white;
    width: 60%;
}

.icon-mobile{
    color: white;
    margin-right: 5px;
    font-family: "Terminus" !important;
    font-size: 1rem !important;
}

.banner-mobile{
    background-color: black;
    max-height: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
.banner-items-mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 90%;
}

.banner-font-mobile{
    color: #77de69;
    font-size: 1rem !important;
    text-align: left;
    font-family: "Terminus" !important;
    text-shadow: #939393 0.7px 1px 1px;
  }

.pic-mobile{
    max-height: 200px;
}

.block-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e8e8e8f8;
    padding: 20px 5px;
    margin: 0px 15px;
    /* margin-bottom: 20px; */
    /* gap: 10px; */
    border-radius: 5px;
    box-shadow: 1px 1px 10px #e8e8e8ab;
    width: 90%;
  }

.block-item-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 10px;
    justify-content: space-between;
    border-radius: 10px;
  
  }
  
.block-item-pic-mobile{
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px #3a3a39;
    margin-bottom: 5px;
  }

.list-mobile{
    padding: 0 !important;
}

.block-feedback-item-mobile{
    width: 250px;
}

.footer-mobile{
    color: #fff;
    font-family: "Terminus" !important;
    font-size: 1rem !important;
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

.footer-block{
    gap: 10px;
}

.footer-logo-mobile{
    height: 40px;
  }
  
.footer-logo-name-mobile{
    height: 10px;
  }